// Generic imports
import React from "react"

// Other components imports
import AboutComponent from "components/AboutComponent"
import Layout from "components/layout"
import SEO from "components/seo"

// Redux imports

// Static imports

// Utils imports

const AboutPage = () => (
  <Layout>
    <SEO title="About TamanSiswa" />
    <AboutComponent />
  </Layout>
)

export default AboutPage
