// Generic imports
import styled from "styled-components"

// Static imports
import tamanTutorStudy from "images/taman-siswa/tamantutor-study1.png"

export const Styles = styled.div`
  background: #ffffff;
  font-size: 1.2em;

  .top-about {
    background: black;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding-top: 30px;
  }

  .top-about-left {
    width: calc(90% - 550px);
    text-align: left;
    display: inline-block;
    padding-top: 50px;
    padding-left: 18px;
    color: white;
  }

  .top-about-left .logo-wrapper img {
    width: 50%;
    margin-bottom: 15px;
    padding: 10px;
    padding-bottom: 5px;
    padding-top: 5px;
    background: white;
    border-radius: 10px;
  }

  .top-about-left div {
    padding-left: 67px;
    padding-top: 90px;
    font-size: 2.75em;
    font-weight: bold;
  }

  .top-about-right {
    width: 650px;
    height: 420px;
    background: url(${tamanTutorStudy}) no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: inline-block;
  }

  .introducing {
    text-align: center;
    padding-top: 120px;
    padding-bottom: 60px;
  }

  .introducing .intro-desc {
    max-width: 800px;
    margin: auto;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .introducing img {
    max-width: 550px;
    width: 80%;
    position: relative;
    right: 10px;
  }

  .why-tutee {
    width: 100%;
    height: 640px;
    display: flex;
    position: relative;
    flex-direction: row;
  }

  .why-tutee img {
    left: 40px;
    bottom: 0;
    height: 90%;
    position: absolute;
    object-fit: cover;
    max-width: 100%;
  }

  .why-tutee .why-tutee-desc {
    padding: 5%;
    flex: 1;
    height: 100%;
    display: flex;
    z-index: 1;
    flex-direction: column;
    justify-content: center;
  }

  .why-tutee .why-tutee-separator {
    flex: 1 1 0%;
  }

  .why-tutor {
    width: 100%;
    height: 640px;
    display: flex;
    position: relative;
    flex-direction: row;
    overflow: hidden;
    background: #f7f7f7;
  }

  .why-tutor img {
    bottom: 0;
    height: 90%;
    position: absolute;
    object-fit: cover;
    max-width: 100%;
    object-position: right;
  }

  .why-tutor .why-tutor-desc {
    padding: 5%;
    max-width: 600px;
    height: 100%;
    display: flex;
    z-index: 1;
    flex-direction: column;
    justify-content: center;
  }

  @media only screen and (max-width: 1400px) {
    .top-about-left {
      width: calc(100% - 541px);
    }

    .top-about-left img {
      width: 480px;
    }

    .top-about-left div {
      padding-top: 65px;
      padding-left: 60px;
      font-size: 2em;
    }

    .top-about-right {
      width: 541px;
      height: 333px;
    }
  }

  @media only screen and (max-width: 1000px) {
    .top-about-left {
      padding-top: 20px;
      width: calc(100% - 442px);
    }

    .top-about-left img {
      width: 420px;
    }

    .top-about-left div {
      padding-left: 50px;
      padding-top: 70px;
      font-size: 1.5em;
    }

    .top-about-right {
      width: 442px;
      height: 272px;
    }

    .why-tutee img {
      object-position: left;
      left: 0;
    }

    .why-tutee .why-tutee-separator {
      display: none;
    }

    .why-tutee .why-tutee-desc {
      width: 100%;
      background: rgba(255, 255, 255, 0.75);
    }
  }

  @media only screen and (max-width: 845px) {
    text-align: center;

    .top-about-left {
      width: 100%;
      padding-top: 30px;
      padding-right: 18px;
    }

    .top-about-left img {
      width: 480px;
    }

    .top-about-left div {
      font-size: 1.75em;
      padding-top: 15px;
      padding-left: 0;
      text-align: center;
      max-width: 100%;
    }

    .top-about-right {
      width: 100vw;
      height: 0;
      padding-bottom: 67%;
    }
  }

  @media only screen and (max-width: 700px) {
    .top-about-left img {
      width: 420px;
    }

    .top-about-left div {
      font-size: 1.5em;
    }
  }

  @media only screen and (max-width: 540px) {
    .top-about-left img {
      width: 360px;
    }

    .top-about-left div {
      font-size: 1.25em;
    }
  }
`
