// Generic imports
import React, { useEffect } from "react"

// Other components imports
import { Styles } from "components/AboutComponent/style"

// Redux imports

// Static imports
// import logoHD from "images/taman-siswa/logo-taman-siswa-hd.svg"
import logoHD from "images/TamanSchool - black 3x.png"
import tamanTutorStudy2 from "images/taman-siswa/tamantutor-study2.png"
import tamanTutorStudy3 from "images/taman-siswa/tamantutor-study3.png"

// Utils imports
import { trackTamanSiswaBackground } from "trackers/amplitude"

const AboutComponent = () => {
  useEffect(() => {
    trackTamanSiswaBackground()
  }, [])

  return (
    <Styles>
      <div className="top-about">
        <div className="top-about-left">
          {/* <div className="logo-wrapper">
            <img src={tamanSiswaLogo} alt="taman-siswa" />
          </div> */}
          <div>The Education We Deserve</div>
        </div>
        <div className="top-about-right"></div>
      </div>
      <div className="introducing">
        <img src={logoHD} alt="Logo" />
        <div className="intro-desc">
          We believe the future of education is decentralized. Where every
          elements of the society, especially students, work together to improve
          our learning ecosystem to be more relevant and joyful for any kinds of
          gift that humans have. Since every kids are unique like seeds of
          plants, it’s crazy to grow their natural talents with just one method
          in one system.
        </div>
      </div>
      <div className="why-tutee">
        <img src={tamanTutorStudy2} alt="studying" />
        <div className="why-tutee-separator"></div>
        <div className="why-tutee-desc">
          Therefore, our mission is simple, yet challenging: Giving the power to
          change education in the hands of every students and local educators.
          In our platform, we deliver services that ease the process of great
          learning experience to happen from students to another students.
        </div>
      </div>
      <div className="why-tutor">
        <img src={tamanTutorStudy3} alt="studying" />
        <div className="why-tutor-separator"></div>
        <div className="why-tutor-desc">
          By the end of the day, if we can nurture the right environment for
          people to grow, we’ll see amazing fruits of learning from a strong and
          healthy garden of students.
        </div>
      </div>
    </Styles>
  )
}

export default AboutComponent
